import React, { useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const Container = styled.div`
  position: relative;
  margin-bottom: ${(isMobile) => (isMobile ? "2rem" : "0")};
  .view-tech-specs {
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;
  }
`;

const InfoIcon = styled.div`
  position: relative;
  margin-right: 0.5rem;
  top: 0.2rem;
  display: inline-block;
  cursor: pointer;
  > div {
    pointer-events: none;
  }
`;

const PopUpContainer = styled.div`
  width: ${(params) => (params.isMobile ? `100%` : `20rem`)};
  padding: 1rem 1.2rem;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
  transition: opacity 0.8s ease;
  pointer-events: none;
  background: white;
  position: absolute;
  box-sizing: border-box;
  opacity: ${(params) => (params.isOpen ? 1 : 0)};
  top: 2rem;
  left: ${(params) => `${params.leftPos}px`};
  ul {
    li {
      font-weight: 300;
    }
  }
`;

export default function TechSpecs({ isMobile }) {
  const infoIconRef = React.createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [leftPos, setLeftPos] = useState(0);

  const open = () => {
    setLeftPos(isMobile ? 0 : infoIconRef.current.offsetLeft);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Container isMobile={isMobile}>
      <InfoIcon ref={infoIconRef} onMouseEnter={open} onMouseLeave={close}>
        {isMobile ? (
          <StaticImage
            src="../images/InfoButton.png"
            alt=""
            width={18}
            quality={90}
            placeholder="none"
          />
        ) : (
          <StaticImage
            src="../images/InfoButton.png"
            alt=""
            width={26}
            quality={90}
            placeholder="none"
          />
        )}
      </InfoIcon>
      <span
        className="view-tech-specs"
        role="button"
        tabIndex={0}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        Validator details
      </span>

      <PopUpContainer isOpen={isOpen} leftPos={leftPos} isMobile={isMobile}>
        <p>Our validators typically run on bare-metal servers featuring at least:</p>
        <ul>
          <li>3.4 Ghz hexa-core CPU</li>
          <li>64 GB DDR4 RAM</li>
          <li>Hardware RNG</li>
          <li>Mirrored NVMe SSDs</li>
          <li>1 Gbit/s network link</li>
        </ul>
      </PopUpContainer>
    </Container>
  );
}
