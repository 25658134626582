import * as React from "react";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import styled from "styled-components";
import StatementParagraph from "../components/statement-paragraph";
import Block from "../components/block";
import SplitFlexBox from "../components/split-flex-box";
import PhotoGallery from "../components/photo-gallery";
import { graphql } from "gatsby";
import AnimatedEntry from "../components/animated-entry";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../consts";
import TechSpecs from "../components/tech-specs";

const Page = styled.div`
  .crypto-address {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
    align-items: center;
    font-weight: 300;
    > div {
      word-break: break-all;
      flex: 1;
      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        max-width: 2rem;
        text-align: right;
      }
    }
  }
`;

const InfrastructurePage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const galleryPhotos = [
    data.gallery1,
    data.gallery2,
    data.gallery3,
    data.gallery4,
    data.gallery5,
  ];

  return (
    <Page className={isMobile ? "is-mobile" : ""}>
      <Hero
        backgroundColor="white"
        text="Trusted providers in blockchain infrastructure"
        imageData={isMobile ? data.mobileHeroImage : data.heroImage}
      />

      <Layout title={"Infrastructure"}>
        <Block justifyContent={"left"} fullHeight={true}>
          <StatementParagraph>
            Our goal is to make interacting with the blockchain simple and
            secure, so by actively building these tools we are helping to
            maintain the integrity of the ecosystem.
          </StatementParagraph>
        </Block>

        <Block noBottomPadding={true}>
          <AnimatedEntry animation="line" />
        </Block>

        <Block noBottomPadding={true}>
          <SplitFlexBox>
            <div>
              <h2>Our validators</h2>
              <TechSpecs isMobile={isMobile} />
            </div>
            <div>
              <h3>Why we need validation</h3>
              <p>
                To improve decentralization of the blockchain a network can use
                a consensus protocol between participating nodes which validate
                and verify the authenticity of transactions. We are supporting
                this expanding ecosystem by running validators for networks like
                the XRP Ledger, Flare Network and Songbird Network.
              </p>
              <h3>Secure by nature</h3>
              <p>
                In a former nuclear bunker 30 meters beneath the streets of
                Stockholm - Pionen, White Mountains is owned and run by Bahnhof,
                Sweden's oldest independent ISP. As it did in 1943, the data
                centre provides us with strong physical security and now
                excellent connectivity, as it facilitates one of Sweden's
                internet exchange points with its triple-redundancy connection
                cables.
              </p>
              <h3>Naturally sustainable</h3>
              <p>
                Much like the efficient networks we support, Pionen is powered
                only by renewable energy, and excess heat from the servers is
                recycled and fed into the local district heating network.
              </p>
              <h3>In good company</h3>
              <p>
                Our most well known validator is "Gimli" validating the XRP Ledger since 2021. It's trusted and included in
                the unique node lists (UNLs) published by the XRP Ledger Foundation and Ripple.
              </p>
              <p>Public key: <strong className="break-line">nHUtmbn4ALrdU6U8pmd8AMt4qKTdZTbYJ3u1LHyAzXga3Zuopv5Y</strong></p>
            </div>
          </SplitFlexBox>
        </Block>

        <Block noBottomPadding={true}>
          <PhotoGallery isMobile={isMobile} photos={galleryPhotos} />
        </Block>

        <Footer />
      </Layout>
    </Page>
  );
};

export const query = graphql`
  query InfrastructurePageQuery {
    heroImage: file(name: { eq: "infrastructure" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImage: file(name: { eq: "infrastructure-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    gallery1: file(name: { eq: "gallery-1" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    gallery2: file(name: { eq: "gallery-2" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    gallery3: file(name: { eq: "gallery-3" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    gallery4: file(name: { eq: "gallery-4" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    gallery5: file(name: { eq: "gallery-5" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default InfrastructurePage;
