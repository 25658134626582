import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { useSwipeable } from "react-swipeable";

const Container = styled.div`
  height: ${(props) =>
    props.containerHeight ? `${props.containerHeight}px ` : `28rem`};
  width: 100%;
  min-height: 17rem;
  display: block;
`;

const CarouselContainer = styled.div`
  position: relative;
`;

const Carousel = styled.div`
  height: auto;

  position: absolute;
  top: 0px;
  left: 0px;

  transition: left 0.4s ease-in-out 0s;
  cursor: pointer;
`;

const WrapContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  > div {
    padding-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const SelectorContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const Selector = styled.div`
  padding-top: 2rem;
  padding-right: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > button:first-child {
    margin-right: 0.2rem;
  }
  > button:last-child {
    margin-left: 0.2rem;
  }
  > span {
    font-weight: 300;
    display: block;
    padding: 0 0.5rem;
  }

  &.is-mobile {
    justify-content: center;
    text-align: center;
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
    font-size: 1.4rem;
  }
`;

const ArrowSelector = styled.button`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 100%;
  border: 2px solid;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    img {
    }
  }
`;

const ImageContainer = styled.div`
  width: ${(props) => (props.imageWidth ? `${props.imageWidth}px ` : `38rem`)};
  height: ${(props) =>
    props.imageHeight ? `${props.imageHeight}px ` : `24rem`};
  overflow: hidden;
  flex: none;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;

export default function PhotoGallery({ photos, isMobile }) {
  const [activePhotoKey, setActivePhotoKey] = useState(0);

  const [windowWidth, setWindowWidth] = useState(undefined);
  const [containerHeight, setContainerHeight] = useState(undefined);
  const [imageWidth, setImageWidth] = useState(windowWidth);
  const [imageHeight, setImageHeight] = useState(undefined);

  const photoCollection = photos.map((p) => getImage(p));

  const containerRef = React.createRef();
  const carouselRef = React.createRef();
  const selectorRef = React.createRef();
  const selectorContainerRef = React.createRef();

  const incrementButtonEnabled =
    activePhotoKey >= 0 && activePhotoKey < photoCollection.length - 1;
  const incrementHandler = () => {
    if (incrementButtonEnabled) {
      setActivePhotoKey(activePhotoKey + 1);
    }
  };

  const decrementButtonEnabled =
    activePhotoKey > 0 && activePhotoKey <= photoCollection.length - 1;
  const decrementHandler = (key) => {
    if (decrementButtonEnabled) {
      setActivePhotoKey(activePhotoKey - 1);
    }
  };

  const clickImageHandler = (key) => {
    setActivePhotoKey(key);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementHandler();
    },
    onSwipedRight: () => {
      decrementHandler();
    },
  });

  useEffect(() => {
    const scrollTo = () => {
      const imageNodes = Array.from(
        carouselRef.current.querySelectorAll(".gallery-image")
      );

      const imageNodesToPass = imageNodes.filter((i, k) => {
        if (k === 0 && isMobile) {
          const containerWidth = containerRef.current.offsetWidth;
          setImageWidth(containerWidth);
        }
        if (k === imageNodes.length - 1 && isMobile) {
          const imageHeight = i.querySelector("div").offsetHeight;
          setImageHeight(imageHeight);
          setContainerHeight(
            imageHeight + selectorContainerRef.current.offsetHeight
          );
        }
        return k < activePhotoKey;
      });

      return imageNodesToPass.reduce((p, imageElement) => {
        return p + imageElement.offsetWidth;
      }, 0);
    };
    carouselRef.current.style.left = `-${scrollTo()}px`;
  }, [
    activePhotoKey,
    carouselRef,
    containerRef,
    selectorRef,
    isMobile,
    selectorContainerRef,
  ]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <Container ref={containerRef} containerHeight={containerHeight}>
      <CarouselContainer {...handlers}>
        <Carousel id="p" ref={carouselRef}>
          <WrapContainer>
            {photoCollection.map((photo, key) => {
              return (
                <ImageContainer
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  className="gallery-image"
                  onClick={() => clickImageHandler(key)}
                  key={key}
                >
                  <GatsbyImage
                    id={`photo-${key}`}
                    alt="Photo"
                    quality={90}
                    image={photo}
                  />
                </ImageContainer>
              );
            })}
          </WrapContainer>
        </Carousel>
      </CarouselContainer>
      <SelectorContainer ref={selectorContainerRef}>
        <Selector ref={selectorRef} className={isMobile ? "is-mobile" : ""}>
          {!isMobile && (
            <ArrowSelector
              onClick={() => {
                decrementHandler();
              }}
              disabled={!decrementButtonEnabled}
              className={decrementButtonEnabled ? "enabled" : "disabled"}
            >
              <StaticImage
                src="../images/Arrow.png"
                alt=""
                width={22}
                quality={90}
                placeholder="none"
                imgStyle={{ transform: "rotate(180deg)" }}
              />
            </ArrowSelector>
          )}

          <span>
            {activePhotoKey + 1}/{photoCollection.length}
          </span>

          {!isMobile && (
            <ArrowSelector
              onClick={() => {
                incrementHandler();
              }}
              disabled={!incrementButtonEnabled}
              className={incrementButtonEnabled ? "enabled" : "disabled"}
            >
              <StaticImage
                src="../images/Arrow.png"
                alt=""
                width={22}
                quality={90}
                placeholder="none"
              />
            </ArrowSelector>
          )}
        </Selector>
      </SelectorContainer>
    </Container>
  );
}
